<template>
  <div class="content_task_box">
    <!--直播详情-->
    <div class="liveing_box" v-if="type == 4 && source == 4">
      <h2>直播主题：{{ taskInfoBox.liveStreaming.theme }}</h2>
      <p>主讲人：{{ taskInfoBox.liveStreaming.anchorUserid }}</p>
      <p>直播时间：{{ taskInfoBox.liveStreaming.livingStart }}</p>
      <p>直播时间长：{{ taskInfoBox.liveStreaming.livingDuration / 60 }}分钟</p>
      <div>打卡健康值：<span class="score_box">{{ taskInfoBox.score }}</span></div>
    </div>
    <!-- 健康数据打卡-->
    <div class="health_conditon_box" v-if="source == 2">
      <div v-if="type == 2">
        <p>高压值：<span>{{ taskInfoBox.bloodPressure.high }}</span>mmHg</p>
        <p>低压值：<span>{{ taskInfoBox.bloodPressure.low }}</span>mmHg</p>
        <p>脉搏：<span>{{ taskInfoBox.bloodPressure.pulse }}</span>bpm</p>
        <p>测量前：{{ taskInfoBox.bloodPressure.activity }}</p>
        <p>打卡时间：{{ taskInfoBox.bloodPressure.measuringTime }}</p>
      </div>
      <div v-if="type == 9">
        <p>总胆固醇：<span>{{ taskInfoBox.bloodFat.totalCholesterol }}</span>mmol/L</p>
        <p>甘油三脂：<span>{{ taskInfoBox.bloodFat.triglyceride }}</span>mmol/L</p>
        <p>高密度胆固醇：<span>{{ taskInfoBox.bloodFat.highDensityLipoprotein }}</span>mmol/L</p>
        <p>低密度胆固醇：<span>{{ taskInfoBox.bloodFat.lowDensityLipoprotein }}</span>mmol/L</p>
        <p>打卡时间：{{ taskInfoBox.bloodFat.measuringTime }}</p>
      </div>
      <div v-if="type == 10">
        <p>血糖值：{{ taskInfoBox.bloodGlucose.timespan }}<span>{{ taskInfoBox.bloodGlucose.bloodGlucose }}</span>mmol/L</p>
        <p>打卡时间：{{ taskInfoBox.bloodGlucose.measuringTime }}</p>
      </div>
      <div v-if="type == 11">
        <p>体重：<span>{{ taskInfoBox.weight.weight }}</span>KG</p>
        <p>打卡时间：{{ taskInfoBox.weight.measuringTime }}</p>
      </div>
      <div>打卡健康值：<span class="score_box">{{ taskInfoBox.score }}</span></div>
    </div>

    <!-- 小程序打卡任务打卡-->

    <div class="list_box" v-if="source == 1">
      <div class="task_info">{{ taskInfoBox.tcommitTaskDto.taskTypeName }}<span>健康值：【{{ taskInfoBox.score }}】分</span>
      </div>
      <div class="task_time">打卡时间：{{ taskInfoBox.tcommitTaskDto.updated }}</div>
      <!--文字+图片0-->
      <div v-if="taskInfoBox.tcommitTaskDto.submitType == 0">
        <div v-for="(item, index) in taskInfoBox.tcommitTaskDto.topicInfos" :key="item.id">
          <div class="answer_title">{{ index + 1 }}、{{ item.title }}</div>
          <div v-if="item.id == topics[index].topicId" class="answers_info">
            <span v-for="(citem, i) in topics[index].answers" :key="i">
              <span v-if="i == 0">{{ citem }}</span>
            </span>
            <img :src="citem" v-if="i > 0" class="answer_img" v-for="(citem, i) in topics[index].answers" :key="i" />
          </div>
        </div>
      </div>
      <!-- 问答1-->
      <div v-if="taskInfoBox.tcommitTaskDto.submitType == 1">
        <div v-for="(item, index) in taskInfoBox.tcommitTaskDto.topicInfos" :key="item.id">
          <div class="answer_title">{{ index + 1 }}、{{ item.title }}</div>
          <div class="answers_info" v-if="item.answerType == 1">
            <span v-for="(citem, i) in item.answers" :key="i"
              :class="citem.id == topics[index].answers[0] ? 'cactive' : ''">
              {{ citem.title }}
            </span>
          </div>
          <div class="answers_info" v-else>
            <span v-for="(citem, i) in item.answers" :key="i"
              :class="citem.id == topics[index].answers[i] ? 'cactive' : ''">
              {{ citem.title }}
            </span>
          </div>
        </div>

      </div>
      <!-- 视频打卡2-->
      <div v-if="taskInfoBox.tcommitTaskDto.submitType == 2">

      </div>
      <!-- 文字打卡3-->
      <div v-if="taskInfoBox.tcommitTaskDto.submitType == 3">
        <div v-for="(item, index) in taskInfoBox.tcommitTaskDto.topicInfos" :key="item.id">
          <div class="answer_title">{{ index + 1 }}、{{ item.title }}</div>
          <div v-if="item.id == topics[index].topicId" class="answers_info">
            <span v-for="(citem, i) in topics[index].answers" :key="i">{{ citem }}</span>
          </div>
        </div>
      </div>
      <!-- 图片打卡4-->
      <div v-if="taskInfoBox.tcommitTaskDto.submitType == 4">
        <div v-for="(item, index) in taskInfoBox.tcommitTaskDto.topicInfos" :key="item.id">
          <div class="answer_title">{{ index + 1 }}、{{ item.title }}</div>
          <div class="answers_info" v-if="item.answerType == 1">
            <img :src="citem.title" v-for="(citem, i) in item.answers" class="answer_img" :key="i"
              :class="item.id == topics[index].topicId && citem.id == topics[index].answers[0] ? 'imgcactive' : ''" />
          </div>
          <div class="answers_info" v-else>
            <img :src="citem.title" v-for="(citem, i) in item.answers" :key="i" class="answer_img"
              :class="item.id == topics[index].topicId && citem.id == topics[index].answers[i] ? 'imgcactive' : ''" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    taskInfoBox: {
      type: Object,
      required: true,
      source: '',
      type: '',
      liveStreaming: null,
    }
  },
  name: "task-info",
  data() {
    return {}
  },
  created() {
    this.type = this.taskInfoBox.type
    this.source = this.taskInfoBox.source
    if (this.source == 1) {
      this.topics = JSON.parse(this.taskInfoBox.tcommitTaskDto.topics)
    }
  }
}
</script>

<style scoped lang="less">
.content_task_box {
  height: 500px;
  padding: 10px;

  .liveing_box {
    line-height: 30px;

    h2 {
      font-size: 18px
    }

    p {
      font-size: 16px;
    }
  }

  .health_conditon_box {
    font-size: 18px;
    line-height: 32px;

    div {
      span {
        font-size: 30px;
        color: #0bf3c2;
      }

      .score_box {
        font-size: 22px;
        color: #ffb043;
      }
    }

  }

  .list_box {
    line-height: 32px;
    font-size: 16px;

    .task_info {
      font-size: 18px;
      font-weight: bold;

      span {
        color: #ffb043;
        margin-left: 20px;
      }
    }

    .answer_title {
      font-weight: bold;
    }

    .answers_info {
      padding-left: 20px;

      span {
        display: block;
      }

      .answer_img {
        width: 120px;
        height: 120px;
        margin-right: 5px;
        border-radius: 5px;
      }

    }
  }

  .score_box {
    font-size: 22px;
    color: #ffb043;
  }

  .cactive {
    color: #0bf3c2;
  }

  .imgcactive {
    border: 1px solid #0bf3c2;
  }
}
</style>
